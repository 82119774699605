const shows = [ 
    {
    date: "27.05.2023",
    place: "FAMA, Białystok",
    title: "Up To Date Festival b2b Projekt New Hope",
    url: "https://www.facebook.com/events/1031353094511663"
  },
];

const Upcoming = () => (
  <ol>
    {shows.map((show, i) => (
      <li className="p-4 mb-4 bg-white bg-opacity-90 rounded md:rounded-lg" key={i}>
        {show.url ? (
          <a
            href={show.url}
            target="_blank"
            rel="noreferrer nofollow"
            className="block"
          >
            <div className="flex justify-between font-stratos-light font-light mb-2 text-sm">
              <span>{show.date}</span>
              <span>{show.place}</span>
            </div>
            <p className="leading-tight text-lg">
              {show.title}
            </p>
          </a>
        ) : (
          <>
            <div className="flex justify-between font-stratos-light font-light mb-2 text-sm">
              <span>{show.date}</span>
              <span>{show.place}</span>
            </div>
            <p className="leading-tight text-lg">
              {show.title}
            </p>
          </>
        )}
      </li>
    ))}
     <li>
            <a
              href="mailto:hi@korsi.space"
              target="_blank"
              rel="noreferrer nofollow"
              className="block p-4 mb-4 bg-white bg-opacity-90 rounded md:rounded-lg"
            >
              <div
                className="flex justify-between font-stratos-light font-light mb-2 text-sm"
              >
                <span>Another day</span>
                <span>Another planet</span>
              </div>
              <p className="text-lg leading-tight">
                Contact me:{" "}
                <span
                  className="text-transparent bg-clip-text bg-gradient-to-br from-orange-400 to-red-600"
                  >hi@korsi.space</span
                >
              </p></a
            >
          </li>
  </ol>
);

export default Upcoming;
